
import { defineComponent } from "vue";

export default defineComponent({
  props: ["text", "closable", "type", "hit", "color", "size", "effect"],
  setup(props, { emit }) {
    const classes = [
      "el-tag",
      props.type ? `el-tag--${props.type}` : "",
      props.size ? `el-tag--${props.size}` : "",
      props.hit && "is-hit",
      props.effect ? `el-tag--${props.effect}` : ""
    ];
    const handleClick = (event: any) => emit("click", event);
    const handleClose = (event: any) => emit("close", event);
    return { classes, handleClick, handleClose };
  }
});
