import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(_ctx.classes),
    style: _normalizeStyle({ backgroundColor: _ctx.color }),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.closable)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: "el-tag__close el-icon-close",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
        }))
      : _createCommentVNode("", true)
  ], 6))
}